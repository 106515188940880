import { render, staticRenderFns } from "./QuestionRespond.vue?vue&type=template&id=40ec1918&scoped=true&"
import script from "./QuestionRespond.vue?vue&type=script&lang=js&"
export * from "./QuestionRespond.vue?vue&type=script&lang=js&"
import style0 from "./QuestionRespond.vue?vue&type=style&index=0&id=40ec1918&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40ec1918",
  null
  
)

export default component.exports